var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tabs',{attrs:{"card":""}},[_c('b-tab',{attrs:{"title":"radio-group"}},[_c('test-wrapper',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('radio-group',{attrs:{"label":"Radio Group: Standard","value":value,"options":['one', 'two']},on:{"input":input}})]}}])}),_c('test-wrapper',{attrs:{"initial-value":"two"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('radio-group',{attrs:{"select":"","label":"Radio Group: Select with value from list","value":value,"options":['one', 'two']},on:{"input":input}})]}}])}),_c('test-wrapper',{attrs:{"initial-value":"two"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('radio-group',{attrs:{"select":"","other":"","label":"Radio Group: Select Other with value from list","value":value,"options":['one', 'two']},on:{"input":input}})]}}])}),_c('test-wrapper',{attrs:{"initial-value":"other value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('radio-group',{attrs:{"select":"","other":"","label":"Radio Group: Select Other with value not in list","value":value,"options":['one', 'two']},on:{"input":input}})]}}])}),_c('test-wrapper',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('radio-group',{attrs:{"select":"","other":"","label":"Radio Group: Select Other with not defined value","value":value,"options":['one', 'two']},on:{"input":input}})]}}])}),_c('test-wrapper',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('radio-group',{attrs:{"select":"","other":"","partner":"","label":"Radio Group: Partner Select Other with undefined value","value":value,"agreement-data":_vm.agreementData},on:{"input":input}})]}}])}),_c('test-wrapper',{attrs:{"initial-value":"1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('radio-group',{attrs:{"select":"","other":"","partner":"","label":"Radio Group: Partner Select Other with partner selected","value":value,"agreement-data":_vm.agreementData},on:{"input":input}})]}}])}),_c('test-wrapper',{attrs:{"initial-value":"Ed"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('radio-group',{attrs:{"select":"","other":"","partner":"","label":"Radio Group: Partner Select Other with other value","value":value,"agreement-data":_vm.agreementData},on:{"input":input}})]}}])}),_c('test-wrapper',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('radio-group',{attrs:{"other":"","none-of-the-above":"","label":"Radio Group: other noneOfTheAbove undefined value","value":value,"options":['one', 'two']},on:{"input":input}})]}}])})],1),_c('b-tab',{attrs:{"title":"input-group"}},[_c('test-wrapper',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('input-group',{attrs:{"question":"A checkbox question","label":"with a value","value":value},on:{"input":input}})]}}])}),_c('test-wrapper',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('input-group',{attrs:{"province":"","label":"input-group province","value":value},on:{"input":input}})]}}])}),_c('test-wrapper',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('input-group',{attrs:{"question":"input-group question date","date":"","label":"input-group question date","value":value},on:{"input":input}})]}}])})],1),_c('b-tab',{attrs:{"title":"list-item"}},[_c('test-wrapper',{attrs:{"initial-value":[]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('list-item',{attrs:{"label":"List Item","value":value},on:{"input":input},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('input-group',{attrs:{"label":"Name"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}}],null,true)})]}}])})],1),_c('b-tab',{attrs:{"title":"check-group"}},[_c('test-wrapper',{attrs:{"initial-value":['one']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('check-group',{attrs:{"label":"Check Group: Standard","value":value,"options":['one', 'two']},on:{"input":input}})]}}])}),_c('test-wrapper',{attrs:{"initial-value":['one']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('check-group',{attrs:{"other":"","label":"Check Group: Other","value":value,"options":['one', 'two']},on:{"input":input}})]}}])}),_c('test-wrapper',{attrs:{"initial-value":['one', 'three']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('check-group',{attrs:{"other":"","label":"Check Group: Other II","value":value,"options":['one', 'two']},on:{"input":input}})]}}])})],1),_c('b-tab',{attrs:{"title":"partner-choice"}},[_c('test-wrapper',{attrs:{"initial-value":"0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('partner-choice',{attrs:{"label":"Partner Choice: Standard","value":value,"agreement-data":_vm.agreementData},on:{"input":input}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }