<script>
export default {
	props: {
		initialValue: [String, Number, Object, Array],
		label: String,
	},
	data() {
		return {
			localValue: this.initialValue,
		};
	},
	methods: {
		input(val) {
			this.localValue = val;
		},
	},
};
</script>

<template>
	<b-row class="py-3 m-3 border-top">
		<b-col>
			<slot :value="localValue" :input="input" />
		</b-col>
		<b-col>{{ localValue }}</b-col>
	</b-row>
</template>
