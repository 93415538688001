<script>
import TestWrapper from '@/components/Form/TestWrapper';

export default {
	components: { TestWrapper },
	data() {
		return {
			agreementData: {
				partner1: {
					name: {
						first: 'Marla',
					},
				},
				partner2: {
					name: {
						first: 'Bob',
					},
				},
			},
			object: {
				radioGroupOtherOne: 'two',
				radioGroupOther: 'other value',
				radioGroupOtherUndefined: undefined,
				radioGroupPartnerPartner2: 1,
				radioGroupPartnerOther: 'someone',
			},
		};
	},
};
</script>

<template>
	<b-tabs card>
		<b-tab title="radio-group">
			<test-wrapper v-slot="{ value, input }">
				<radio-group
					label="Radio Group: Standard"
					:value="value"
					:options="['one', 'two']"
					@input="input"
				/>
			</test-wrapper>

			<test-wrapper v-slot="{ value, input }" initial-value="two">
				<radio-group
					select
					label="Radio Group: Select with value from list"
					:value="value"
					:options="['one', 'two']"
					@input="input"
				/>
			</test-wrapper>

			<test-wrapper v-slot="{ value, input }" initial-value="two">
				<radio-group
					select
					other
					label="Radio Group: Select Other with value from list"
					:value="value"
					:options="['one', 'two']"
					@input="input"
				/>
			</test-wrapper>

			<test-wrapper v-slot="{ value, input }" initial-value="other value">
				<radio-group
					select
					other
					label="Radio Group: Select Other with value not in list"
					:value="value"
					:options="['one', 'two']"
					@input="input"
				/>
			</test-wrapper>

			<test-wrapper v-slot="{ value, input }">
				<radio-group
					select
					other
					label="Radio Group: Select Other with not defined value"
					:value="value"
					:options="['one', 'two']"
					@input="input"
				/>
			</test-wrapper>

			<test-wrapper v-slot="{ value, input }">
				<radio-group
					select
					other
					partner
					label="Radio Group: Partner Select Other with undefined value"
					:value="value"
					:agreement-data="agreementData"
					@input="input"
				/>
			</test-wrapper>

			<test-wrapper v-slot="{ value, input }" initial-value="1">
				<radio-group
					select
					other
					partner
					label="Radio Group: Partner Select Other with partner selected"
					:value="value"
					:agreement-data="agreementData"
					@input="input"
				/>
			</test-wrapper>

			<test-wrapper v-slot="{ value, input }" initial-value="Ed">
				<radio-group
					select
					other
					partner
					label="Radio Group: Partner Select Other with other value"
					:value="value"
					:agreement-data="agreementData"
					@input="input"
				/>
			</test-wrapper>

			<test-wrapper v-slot="{ value, input }">
				<radio-group
					other
					none-of-the-above
					label="Radio Group: other noneOfTheAbove undefined value"
					:value="value"
					:options="['one', 'two']"
					@input="input"
				/>
			</test-wrapper>
		</b-tab>
		<!--
    <b-tab title="table-input">
      <test-wrapper :initialValue="[]" v-slot="{ value, input }">
        <table-input
          label="Who will be responsible for specified expenses until the Family Residence is sold?"
          :fields="['who', 'what']"
          v-slot="{ item }"
          v-bind:value="value"
          v-on:input="input"
        >
          <b-container>
            <b-row>
              <b-col>
                <partner-choice
                  label="Who"
                  v-model="item.who"
                  :agreementData="agreementData"
                  :additionalChoices="['both parties']"
                />
              </b-col>
              <b-col>
                <radio-group
                  select
                  other
                  label="What"
                  v-model="item.what"
                  :options="['the Mortgage', 'the Line of Credit']"
                />
              </b-col>
            </b-row>
          </b-container>
        </table-input>
      </test-wrapper>

      <test-wrapper :initialValue="[]" v-slot="{ value, input }">
        <table-input
          label="table-input using list of strings"
          :objectPrototype="() => ''"
          v-slot="{ item, input: itemInput }"
          v-bind:value="value"
          v-on:input="input"
        >
          <b-form-group label="label">
            <b-form-input v-bind:value="item" v-on:input="itemInput" />
          </b-form-group>
        </table-input>
      </test-wrapper>
    </b-tab>
    -->
		<b-tab title="input-group">
			<test-wrapper v-slot="{ value, input }">
				<input-group
					question="A checkbox question"
					label="with a value"
					:value="value"
					@input="input"
				/>
			</test-wrapper>
			<test-wrapper v-slot="{ value, input }">
				<input-group
					province
					label="input-group province"
					:value="value"
					@input="input"
				/>
			</test-wrapper>
			<test-wrapper v-slot="{ value, input }">
				<input-group
					question="input-group question date"
					date
					label="input-group question date"
					:value="value"
					@input="input"
				/>
			</test-wrapper>
		</b-tab>
		<b-tab title="list-item">
			<test-wrapper v-slot="{ value, input }" :initial-value="[]">
				<list-item
					v-slot="{ item }"
					label="List Item"
					:value="value"
					@input="input"
				>
					<input-group v-model="item.name" label="Name" />
				</list-item>
			</test-wrapper>
		</b-tab>
		<b-tab title="check-group">
			<test-wrapper v-slot="{ value, input }" :initial-value="['one']">
				<check-group
					label="Check Group: Standard"
					:value="value"
					:options="['one', 'two']"
					@input="input"
				/>
			</test-wrapper>
			<test-wrapper v-slot="{ value, input }" :initial-value="['one']">
				<check-group
					other
					label="Check Group: Other"
					:value="value"
					:options="['one', 'two']"
					@input="input"
				/>
			</test-wrapper>
			<test-wrapper
				v-slot="{ value, input }"
				:initial-value="['one', 'three']"
			>
				<check-group
					other
					label="Check Group: Other II"
					:value="value"
					:options="['one', 'two']"
					@input="input"
				/>
			</test-wrapper>
		</b-tab>
		<b-tab title="partner-choice">
			<test-wrapper v-slot="{ value, input }" initial-value="0">
				<partner-choice
					label="Partner Choice: Standard"
					:value="value"
					:agreement-data="agreementData"
					@input="input"
				/>
			</test-wrapper>
		</b-tab>
	</b-tabs>
</template>
